import { useState, useEffect } from "react";
import { useTheme } from "next-themes";
import { CgDarkMode } from "react-icons/cg";
import { IconContext } from "react-icons/lib";
import { ThemeIcon } from "./ThemeChangerElements";

const ThemeChanger = () => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  useEffect(() => setMounted(true), []);

  if (!mounted) return null;

  return (
    <>
      <IconContext.Provider value={{ className: "theme-icon" }}>
        <ThemeIcon id="theme-icon">
          {theme === "dark" ? (
            <CgDarkMode onClick={() => setTheme("light")} />
          ) : (
            <CgDarkMode onClick={() => setTheme("dark")} />
          )}
        </ThemeIcon>
      </IconContext.Provider>
    </>
  );
};

export default ThemeChanger;