import styled from "styled-components";

export const FooterContainer = styled.footer``;

export const FooterWrap = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const WebsiteRights = styled.small`
  margin: 10px 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 400;
  z-index: 1;
`;

export const WebsiteLinkSpan = styled.span`
  &.legal {
    font-size: 0.5rem;
    padding: 0 0 20px 0;
    margin: -6px 0 0 0;
  }
`;
