import styled from "styled-components";

export const Nav = styled.nav`
  height: 140px;
  width: 100vw;
  margin-top: ${({ scrollNav }) => (scrollNav ? "-35px" : "0px")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    margin-top: 0;
  }

  @media screen and (max-width: 768px) {
    height: 90px;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 120px;
  width: 800px;
  max-width: 800px;
  margin-top: 40px;
  z-index: 1;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 30px;
    cursor: pointer;
    color: #000;
  }
`;

export const NavMenu = styled.menu`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-left: auto;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 40px;
`;
