import Link from "next/link";
import {
  FooterContainer,
  FooterWrap,
  WebsiteRights,
  WebsiteLinkSpan,
} from "./FooterElements";

const Footer = () => {
  return (
    <>
      <FooterContainer id="footer">
        <FooterWrap>
          <WebsiteRights id="website-rights">
            stoicspace © {new Date().getFullYear()}
          </WebsiteRights>
          <WebsiteLinkSpan className="legal">
            <Link
              href="/legal"
              className="legal-link"
              passHref
              prefetch={false}
            >
              Legal Information
            </Link>
          </WebsiteLinkSpan>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};

export default Footer;
