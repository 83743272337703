import styled from "styled-components";

export const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 700px;
  max-width: calc(100% - 20px);
  margin: 0 auto;
  padding: 0 0.5rem;

  @media screen and (max-width: 836px) {
    width: unset;
    padding: 0 2rem;
  }
`;
