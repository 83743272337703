import { useEffect } from "react";
import Script from "next/script";
import { Router, useRouter } from "next/router";
import { Open_Sans } from "@next/font/google";
import { logEvent } from "firebase/analytics";
import { createGlobalStyle } from "styled-components";
import { ThemeProvider } from "next-themes";
import { motion } from "framer-motion";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { analytics } from "@/config/firebase";
import Layout from "@/components/Layout";

const openSans = Open_Sans({
  subsets: ["latin"],
  variable: "--primary-font",
});

const GlobalStyle = createGlobalStyle`
  //Boilerplate
  html {
    height: 100%;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }
  
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--primary-font);
    font-size: 16px;
    min-height: 100%;
    line-height: 1.5;
  }

  //Global
  a {
    text-decoration: none;
    color: inherit;
  }

  @keyframes react-loading-skeleton {
    100% {
      transform: translateX(100%);
    }
  }

  .react-loading-skeleton::after {
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      90deg,
      var(--base-color),
      var(--highlight-color),
      var(--base-color)
    );
    transform: translateX(-100%);

    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  .nav-link {
    font-weight: 400;
    border-radius: 5px;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    margin-left: 6px;
    height: 100%;
    cursor: pointer;
  }
  
  body #blog-text img {
    padding: 20px 0;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 480px) {
      padding: 15px 0;
    }
  }

  .avatar { 
    border-radius: 20px;
  }

  .toaster {
    margin-top: 100px;
  }

  //Light-mode theme
  :root {
    color: #000;
    background-color: #fff;

    #dark-mode-icon {
      color: #000;
    }

    #navigation {
      background-opacity: 0.95;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur( 19.0px );
      -webkit-backdrop-filter: blur( 19.0px );
    }

    .nav-link {
      :hover {
        background-color: #EAEAEA;
        transition: 0.4s all ease;
      }
    }

    #nav-mobile-icon {
      color: #000;
    }

    .active {
      background-color: #d2d2d2;\
      text-shadow: 0 0 0.01px #000, 0 0 0.01px #000;

      :hover {
        background-color: #d2d2d2 !important;
      }
    }

    .active-sidebar {
      :before {
        content: "• ";
        color: #92f2d4;
        margin-left: -14px;
      } 
    }

    .react-loading-skeleton {
      --base-color: #c5c5c5;
      --highlight-color: #f5f5f5;
      --animation-duration: 1.5s;
      --animation-direction: normal;
      --pseudo-element-display: block; /* Enable animation */

      background-color: var(--base-color);

      width: 100%;
      border-radius: 0.25rem;
      display: inline-flex;
      line-height: 1;

      position: relative;
      overflow: hidden;
      z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
    }

    #newsletter {
      background-color: #EBFFFC;
      border: solid 1px #92f2d4;
    }

    #website-link, #footer-portfolio-link, #blog-title, #blog-text a, #legal-title, #legal-text a {
      text-decoration: none;
      color: inherit;
      border-bottom: 1px solid #92f2d4;

      :hover {
        color: rgba(0, 0, 0, 0.6);
        transition: 0.2s all ease;
      }
    }

    #website-rights {
      color: #000;
    }

    #legal {
      color: #000;
    }

    .about-quote {
      border-left: solid 1px rgba(146,242,212, 0.7);
    }
  }

  //Dark-mode theme
  [data-theme="dark"] {
    color: #fff;
    background-color: #1A1919;

    #dark-mode-icon {
      color: #fff;
    }

    #navigation {
      background-opacity: 0.95;
      background-color: rgba(26, 25, 25, 0.8);
      backdrop-filter: blur( 19.0px );
      -webkit-backdrop-filter: blur( 19.0px );
    }

    .nav-link {
      :hover {
        background-color: #666;
        transition: 0.4s all ease;
      }
    }

    #nav-mobile-icon {
      color: #fff;
    }

    .active {
      background-color: #595959;
      text-shadow: 0 0 0.01px #fff, 0 0 0.01px #fff;

      :hover {
        background-color: #595959 !important;
      }
    }

    .active-sidebar {
      :before {
        content: "• ";
        color: #2189AC;
        margin-left: -14px;
      } 
    }

    .react-loading-skeleton {
      --base-color: #9f9f9f;
      --highlight-color: #f5f5f5;
      --animation-duration: 1.5s;
      --animation-direction: normal;
      --pseudo-element-display: block; /* Enable animation */

      background-color: var(--base-color);

      width: 100%;
      border-radius: 0.25rem;
      display: inline-flex;
      line-height: 1;

      position: relative;
      overflow: hidden;
      z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
    }

    #newsletter {
      background-color: #207088;
      border: solid 1px #2189AC;
      color:#fff;
    }

    #newsletter input {
      background-color: #333;
      color: #fff;
    }

    #newsletter button {
      background-color: #444;
      color: #fff;
    }

    #website-link, #footer-portfolio-link, #blog-title, #blog-text a, #legal-title, #legal-text a {
      text-decoration: none;
      color: inherit;
      border-bottom: 1px solid #2189AC;

      :hover {
        color: rgba(255, 255, 255, 0.6);
        transition: 0.2s all ease;
      }
    }

    #website-rights {
      color: #fff;
    }

    #legal {
      color: #fff;
    }

    .about-quote {
      border-left: solid 1px rgba(33,137,172, 0.7);
    }
  }
`;

export default function App({ Component, pageProps }) {
  Router.events.on("routeChangeStart", () => NProgress.start());
  Router.events.on("routeChangeComplete", () => NProgress.done());
  Router.events.on("routeChangeError", () => NProgress.done());

  const router = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logFirebaseEvent = (url) => {
        logEvent(analytics, "screen_view", {
          firebase_screen: url,
          firebase_screen_class: url,
        });
      };

      router.events.on("routeChangeComplete", logFirebaseEvent);

      return () => {
        router.events.off("routeChangeComplete", logFirebaseEvent);
      };
    }
  }, [router.events]);

  return (
    <>
      <GlobalStyle />
      <Script
        id="microsoft-clarity"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_API_KEY}");
          `,
        }}
      />
      <ThemeProvider>
        <div className={openSans.className}>
          <Layout>
            <motion.div
              key={router.route}
              initial="pageInitial"
              animate="pageAnimate"
              variants={{
                pageInitial: {
                  opacity: 0,
                },
                pageAnimate: {
                  opacity: 1,
                },
              }}
            >
              <Component {...pageProps} />
            </motion.div>
          </Layout>
        </div>
      </ThemeProvider>
    </>
  );
}
