import styled from "styled-components";

export const ThemeIcon = styled.div`
  margin-top: 22px;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  font-size: 45px;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
    margin-top: 10px;
  }
  
  @media screen and (max-width: 480px) {
    margin-left: 7px;
    margin-top: 6px;
  }
`;