import { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import {
  Nav,
  NavbarContainer,
  MobileIcon,
  NavMenu,
  NavItem,
} from "./NavbarElements";
import ThemeChanger from "@/components/ThemeChanger";

const Navbar = ({ toggle }) => {
  const router = useRouter();
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <>
      <IconContext.Provider value={{ className: "nav-mobile-icon" }}>
        <Nav scrollNav={scrollNav} id="navigation">
          <NavbarContainer>
            <MobileIcon onClick={toggle} id="nav-mobile-icon">
              <FaBars />
            </MobileIcon>
            <ThemeChanger />
            <NavMenu>
              <NavItem>
                <Link
                  href="/blog"
                  className={
                    router.pathname == "/blog" ? "active nav-link" : "nav-link"
                  }
                  passHref
                  prefetch={false}
                >
                  Blog
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  href="/quotes"
                  className={
                    router.pathname == "/quotes"
                      ? "active nav-link"
                      : "nav-link"
                  }
                  passHref
                  prefetch={false}
                >
                  Quotes
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  href="/about"
                  className={
                    router.pathname == "/about" ? "active nav-link" : "nav-link"
                  }
                  passHref
                  prefetch={false}
                >
                  About
                </Link>
              </NavItem>
              <NavItem>
                <Link href="/" className="nav-link" passHref prefetch={false}>
                  Home
                </Link>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
