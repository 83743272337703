import { useRouter } from "next/router";
import Link from "next/link";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  const router = useRouter();

  return (
    <SidebarContainer id="sidebar" isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink>
            <Link
              href="/blog"
              className={router.pathname == "/blog" ? "active-sidebar" : ""}
              onClick={toggle}
              passHref
            >
              Blog
            </Link>
          </SidebarLink>
          <SidebarLink>
            <Link
              href="/quotes"
              className={router.pathname == "/quotes" ? "active-sidebar" : ""}
              onClick={toggle}
              passHref
            >
              Quotes
            </Link>
          </SidebarLink>
          <SidebarLink>
            <Link
              href="/about"
              className={router.pathname == "/about" ? "active-sidebar" : ""}
              onClick={toggle}
              passHref
            >
              About
            </Link>
          </SidebarLink>
          <SidebarLink>
            <Link href="/" onClick={toggle} passHref>
              Home
            </Link>
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
